import "./index.scss";

import { memo, useState, useCallback, type ReactNode } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { useMobileScreenClass } from "common/core/responsive";
import { LongFormattedDateTime } from "common/core/format/date";
import FormattedNotarialAct from "common/core/format/formatted_notarial_act";
import { useQuery } from "util/graphql";
import { userFullName } from "util/user";
import { onlyRequiresProofing } from "util/completion_requirements/completion_requirements_text";
import { asArchive } from "common/details/bundle/save_all";
import signedDocIcon from "assets/images/signed-doc-icon.svg";
import { Heading, Paragraph } from "common/core/typography";

import RetrievalViewerDetailsQuery, {
  type RetrievalViewerDetails_viewer_retrieval_documentBundle as DocumentBundle,
  type RetrievalViewerDetails_viewer_retrieval_document as Document,
  type RetrievalViewerDetails_viewer_retrieval_meeting as Meeting,
} from "./retrieval_query.graphql";
import RetrievalDetailsFailureModal from "./failure_modal";

type StateParams = {
  retrievalPin: string;
};
type RetrievalDetailsContainerProps = {
  children: ReactNode;
};
type DocumentsSectionProps = {
  documentBundle: DocumentBundle;
  useCompactUi: boolean;
};
type DetailsSectionProps = {
  documentBundle: DocumentBundle;
  meeting: Meeting;
  document: Document;
  useCompactUi: boolean;
};

function RetrievalDetailsContainer({ children }: RetrievalDetailsContainerProps) {
  return (
    <div data-automation-id="retrieval-details" className="RetrievalDetails">
      {children}
    </div>
  );
}

function DocumentsSection({ documentBundle, useCompactUi }: DocumentsSectionProps) {
  const [downloading, setDownloading] = useState(false);
  const { proofIdentityReport } = documentBundle;

  const downloadAll = useCallback(async () => {
    setDownloading(true);
    await asArchive(documentBundle);
    setDownloading(false);
  }, [documentBundle]);

  function getDocumentEntry(name: string, id?: string) {
    return (
      <div
        data-automation-id="document-item"
        key={id || name}
        className="RetrievalDetails--main--documents--item"
      >
        <img className="RetrievalDetails--main--documents--item--icon" src={signedDocIcon} alt="" />
        <Paragraph className="RetrievalDetails--main--documents--item--name" title={name}>
          {name}
        </Paragraph>
      </div>
    );
  }

  const documentCount = documentBundle.documents.edges.length + (proofIdentityReport ? 1 : 0);
  const subheader = (
    <Heading
      level="h2"
      textStyle="subtitle"
      className="RetrievalDetails--main--documents--subheader"
    >
      <FormattedMessage
        id="53779905-d57d-48d0-a43e-0ea28e919d8c"
        defaultMessage="{documentCount, plural, one {Document} other {Documents}}"
        values={{ documentCount }}
      />
    </Heading>
  );

  const documents = documentBundle.documents.edges.map(({ node: document }) =>
    getDocumentEntry(document.name!, document.id),
  );
  // We do this because the identity report isn't included in the bundle documents
  // however we want to allow for rendering and download as simply as possible by combining it here.
  if (proofIdentityReport) {
    documents.push(getDocumentEntry(proofIdentityReport.filename!));
  }
  const button = (
    <Button
      automationId="download-all-button"
      className="RetrievalDetails--main--documents--download-button"
      onClick={downloadAll}
      isLoading={downloading}
      buttonSize="large"
      buttonColor="action"
      variant="primary"
    >
      <FormattedMessage
        id="93d66799-073c-4ae3-aa3f-a31a28676812"
        defaultMessage="Download {icon}"
        values={{ icon: <Icon name="arrow-down-square" /> }}
      />
    </Button>
  );

  if (useCompactUi) {
    return (
      <div data-automation-id="documents-section" className="RetrievalDetails--main--documents">
        {button}
        {documents}
      </div>
    );
  }

  return (
    <div data-automation-id="documents-section" className="RetrievalDetails--main--documents">
      {subheader}
      {documents}
      {button}
    </div>
  );
}

function DetailsSection(props: DetailsSectionProps) {
  const { documentBundle, meeting, document, useCompactUi } = props;
  const publicAgentDetails = meeting.publicAgentDetails!;

  const [detailsCollapsed, setDetailsCollapsed] = useState(true);
  const itemCx = classnames("RetrievalDetails--main--details--item", {
    "RetrievalDetails--main--details--item__collapsed": detailsCollapsed && useCompactUi,
  });

  return (
    <div data-automation-id="details-section" className="RetrievalDetails--main--details">
      <Heading
        level="h2"
        textStyle="subtitle"
        className="RetrievalDetails--main--details--subheader"
        onClick={() => setDetailsCollapsed(!detailsCollapsed)}
      >
        <FormattedMessage id="e19fb0d5-93e4-4798-a973-9dd99ac66630" defaultMessage="Details" />
        {useCompactUi && (
          <Icon
            className="RetrievalDetails--main--details--subheader--icon"
            name={detailsCollapsed ? "caret-right" : "caret-down"}
          />
        )}
      </Heading>
      <div className={itemCx}>
        <Paragraph className="RetrievalDetails--main--details--item--label">
          <FormattedMessage
            id="9bdebcd7-b299-48ad-b6cb-1c440e65ec67"
            defaultMessage="Video meeting"
          />
        </Paragraph>
        <Paragraph className="RetrievalDetails--main--details--item--value">
          <LongFormattedDateTime value={meeting.timeFrame!.startedAt} asTimeZone="UTC" />
        </Paragraph>
      </div>
      <div className={itemCx}>
        <Paragraph className="RetrievalDetails--main--details--item--label">
          <FormattedMessage id="276e39ce-4ba4-4d12-97b8-c7dd69e53c03" defaultMessage="Access ID" />
        </Paragraph>
        <Paragraph className="RetrievalDetails--main--details--item--value">
          {documentBundle.retrievalId || document.retrievalId}
        </Paragraph>
      </div>
      {meeting.notarialActs!.length > 0 && (
        <div className={itemCx}>
          <Paragraph
            data-automation-id="notarial-acts"
            className="RetrievalDetails--main--details--item--label"
          >
            <FormattedMessage
              id="2c19d0ad-6fab-4e89-9602-f918401dd1d5"
              defaultMessage="{notarialActsCount, plural, one {Notarial act} other {Notarial acts}}"
              values={{ notarialActsCount: meeting.notarialActs!.length }}
            />
          </Paragraph>
          <div className="RetrievalDetails--main--details--item--value">
            {meeting.notarialActs!.map((act) => (
              <Paragraph key={act as string}>
                <FormattedNotarialAct act={act!} />
              </Paragraph>
            ))}
          </div>
        </div>
      )}
      {onlyRequiresProofing(documentBundle.completionRequirements) && (
        <div className={itemCx}>
          <Paragraph className="RetrievalDetails--main--details--item--label">
            <FormattedMessage
              id="4584f9d0-ad82-4f84-9756-d11b542394d9"
              defaultMessage="Agent act"
            />
          </Paragraph>
          <Paragraph className="RetrievalDetails--main--details--item--value">
            <FormattedMessage
              id="5ffa66a5-383f-4f9e-8217-a2875da2dc2b"
              defaultMessage="Identity confirmation"
            />
          </Paragraph>
        </div>
      )}
      <div className={itemCx}>
        <Paragraph className="RetrievalDetails--main--details--item--label">
          <FormattedMessage
            id="1d825be9-9f5f-45a3-b968-99414fb02273"
            defaultMessage="Notary Public"
          />
        </Paragraph>
        <Paragraph className="RetrievalDetails--main--details--item--value">
          {userFullName(publicAgentDetails)}
        </Paragraph>
      </div>
      <div className={itemCx}>
        <Paragraph className="RetrievalDetails--main--details--item--label">
          <FormattedMessage
            id="4495b08d-030e-4ec1-9879-d73d121a5ac4"
            defaultMessage="Notary location"
          />
        </Paragraph>
        <Paragraph className="RetrievalDetails--main--details--item--value">
          {publicAgentDetails.__typename === "PublicNotaryAgentDetails" &&
            publicAgentDetails.usStateName}
        </Paragraph>
      </div>
      <div className={itemCx}>
        <Paragraph className="RetrievalDetails--main--details--item--label">
          <FormattedMessage
            id="0419dff4-41fc-484e-90e7-12a4f2ac36c9"
            defaultMessage="Notary commission number"
          />
        </Paragraph>
        <Paragraph className="RetrievalDetails--main--details--item--value">
          {publicAgentDetails.__typename === "PublicNotaryAgentDetails" &&
            publicAgentDetails.notaryId}
        </Paragraph>
      </div>
      <div className={itemCx}>
        <Paragraph className="RetrievalDetails--main--details--item--label">
          <FormattedMessage
            id="70039a50-504a-4af2-b9d2-9115856b466f"
            defaultMessage="Notary commission expiration"
          />
        </Paragraph>
        <Paragraph className="RetrievalDetails--main--details--item--value">
          {publicAgentDetails.__typename === "PublicNotaryAgentDetails" && (
            <LongFormattedDateTime
              value={publicAgentDetails.commissionExpiration}
              asTimeZone="UTC"
            />
          )}
        </Paragraph>
      </div>
    </div>
  );
}

function RetrievalDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryArgs] = useSearchParams();
  const retrievalId = queryArgs.get("retrievalId") as string;
  const { retrievalPin } = (location.state || {}) as StateParams;

  const useCompactUi = useMobileScreenClass();

  const { data, loading, error } = useQuery(RetrievalViewerDetailsQuery, {
    variables: { retrievalId, retrievalPin },
  });

  if (loading) {
    return (
      <RetrievalDetailsContainer>
        <LoadingIndicator />
      </RetrievalDetailsContainer>
    );
  }

  const retrieval = data?.viewer.retrieval;
  if (error || !retrieval) {
    return (
      <RetrievalDetailsContainer>
        <RetrievalDetailsFailureModal onRetry={() => navigate("/")} />
      </RetrievalDetailsContainer>
    );
  }

  const { meeting, documentBundle, document } = retrieval;

  const documentCount =
    documentBundle.documents.edges.length + (documentBundle.proofIdentityReport ? 1 : 0);
  return (
    <RetrievalDetailsContainer>
      <div
        className={classnames(
          "RetrievalDetails--main",
          !meeting && "RetrievalDetails--main--centered",
        )}
      >
        <Heading
          level="h1"
          textStyle="subtitle"
          data-automation-id="retrieval-details-header"
          className="RetrievalDetails--main--header"
        >
          <FormattedMessage
            id="160e4dd8-2777-4d03-a99a-5ed7d1f9dcb0"
            defaultMessage="Completed {documentCount, plural, one {document} other {documents}}"
            values={{ documentCount }}
          />
        </Heading>
        <div className="RetrievalDetails--main--content">
          <DocumentsSection documentBundle={documentBundle} useCompactUi={useCompactUi} />
          {meeting && (
            <DetailsSection
              documentBundle={documentBundle}
              meeting={meeting}
              document={document!}
              useCompactUi={useCompactUi}
            />
          )}
        </div>
      </div>
    </RetrievalDetailsContainer>
  );
}

export default memo(RetrievalDetails);
