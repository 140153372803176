import "./index.scss";

import { useEffect } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedPasswordField } from "common/form/fields/password";
import Button from "common/core/button";
import { UserTermsOfService, MasterPrivacyPolicyUrl } from "common/tos";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import { Paragraph } from "common/core/typography";

import type { HandleSubmitArgs } from "..";

function normalizeRetrievalCode(code: string | undefined | null) {
  // Our backend token generator does not produce tokens that are lowercase with spaces
  // To make retrieval simplier we make sure to trim spaces and make characters uppercase.
  return code ? code.trim().toUpperCase() : "";
}

type FormData = {
  retrievalId: string;
  retrievalPin: string;
};

type Props = {
  onRetrievalSubmit: (args: HandleSubmitArgs) => void;
  retrievalId?: string | null;
};

function RetrievalRequestForm(
  props: Pick<InjectedFormProps<FormData, Props>, "handleSubmit" | "initialize"> & Props,
) {
  const { onRetrievalSubmit, handleSubmit, initialize, retrievalId } = props;

  useEffect(() => {
    initialize({
      retrievalId: retrievalId || "",
    });
  }, []);

  const onSubmit = ({ retrievalId, retrievalPin }: FormData) => {
    onRetrievalSubmit({
      retrievalPin: normalizeRetrievalCode(retrievalPin),
      retrievalId: normalizeRetrievalCode(retrievalId),
    });
  };

  return (
    <form className="RetrievalRequestForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Paragraph
        data-automation-id="retrieval-request-form-info"
        className="RetrievalRequestForm--info"
      >
        <FormattedMessage
          id="decd16b9-9a7f-4a94-897b-af951ee6ef98"
          defaultMessage="{retrievalId, select, null{To view and download documents, enter the Access ID and PIN found in your email:} other{To view and download documents, enter the PIN found in your email:}}"
          values={{ retrievalId: retrievalId || null }}
        />
      </Paragraph>

      <DeprecatedFormRow>
        <FormGroup fields={["retrievalId"]} disableFormRowStyle>
          <DeprecatedTextField
            id="retrievalId"
            data-automation-id="retrieval-id"
            name="retrievalId"
            label="Access ID"
            autoComplete="off"
            useNativeLabel
            useStyledInput
          />
          <FormGroupErrors fields={["retrievalId"]} />
        </FormGroup>
      </DeprecatedFormRow>

      <DeprecatedFormRow>
        <FormGroup fields={["retrievalPin"]} disableFormRowStyle>
          <DeprecatedPasswordField
            id="retrievalPin"
            data-automation-id="retrieval-pin"
            name="retrievalPin"
            label="PIN"
            placeholder=""
            autoComplete="off"
            useNativeLabel
            useStyledInput
          />
          <FormGroupErrors fields={["retrievalPin"]} />
        </FormGroup>
      </DeprecatedFormRow>

      <Button
        automationId="retrieve-button"
        type="submit"
        buttonColor="action"
        buttonSize="large"
        variant="primary"
        fullwidth
      >
        <FormattedMessage
          id="3bb5e21e-cbd3-4a41-837d-ffe7f78570ca"
          defaultMessage="Access documents"
        />
      </Button>
      <Paragraph className="RetrievalRequestForm--tos" size="small" textAlign="center">
        <FormattedMessage
          id="7efc848e-3570-4789-978a-14c847f5ba87"
          defaultMessage="By retrieving a document, and clicking 'Access documents' you are agreeing to the {termsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}."
          values={{
            termsOfUse: <UserTermsOfService underlined />,
            privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
          }}
        />
      </Paragraph>
    </form>
  );
}

export default reduxForm<FormData, Props>({
  form: "retrieveDocument",
  validate: composeValidators(
    validatePresence({ field: "retrievalId", label: "Access ID" }),
    validatePresence({ field: "retrievalPin", label: "PIN" }),
  ),
})(RetrievalRequestForm);
